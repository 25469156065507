.header {
    background-image: url('./../../assets/5.png');
    height: 35vh;
    background-repeat: no-repeat;
    background-size: cover;
        position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            background-position: bottom;

}
@media (max-width: 775px) {
    .header {
        background-image: url('./../../assets/5.png');
        height: 9vh;
        background-repeat: no-repeat;
        background-size: contain;
            position: relative;
                display: flex;
                justify-content: center;
                align-items: center;
                background-position: bottom;
    
    }

}
.overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.50);
    z-index: 2;
}