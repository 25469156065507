.myForm {
display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 8px;
}

.myForm input {
        padding: 20px;
            background-color: #F2F2F2;
            color: #000;
            border-radius: 8px;
            margin: 10px;
            outline: none;
            border: navajowhite;
            width: 90%;
}

.myForm input::placeholder {
    color: #BCB6B6;
}