.step::before {
    content: '';
    display: inline-block;
    padding: 5px;
    color: #FFF;
    transform: rotate(45deg);
    background: #FFC82D;
    margin: 0px 4px;
    text-align: center;
    border-radius: 50%;
}

@media (max-width: 775px) {

    .step::before {
        content: '';
        display: inline-block;
        padding: 5px;
        color: #FFF;
        transform: rotate(45deg);
        background: #FFC82D;
        margin: 0px 4px;
        text-align: center;
        border-radius: 50%;
    }

}